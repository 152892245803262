<template>
    <v-layout justify-center>
        <v-flex xs12 sm8 md4>
            <v-subheader v-show="headerRule"><h2 style="text-align:center">{{ $t('login.placard') }}</h2>
                <br />
            </v-subheader>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-text-field outline clearable
                        v-model="username"
                        prepend-icon="person"
                        name="username"
                        :label="loginHint()"
                        type="text"
                        :rules="loginRules" 
                    ></v-text-field>
                    <v-text-field outline clearable
                        v-model="password"
                        prepend-icon="lock"
                        name="password"
                        :label="$t('login.password')"
                        :append-icon="showPass ? 'visibility_off' : 'visibility'"
                        :type="showPass ? 'text' : 'password'"
                        :rules="passwordRules"
                        @click:append="showPass = !showPass"
                    ></v-text-field>
                    <v-text-field outline clearable
                        v-model="verifyCode"
                        prepend-icon="security"
                        name="verifyCode"
                        :label="verifyHint()"
                        type="text"
                        @click:append="showPass = !showPass"
                    ></v-text-field>
                    <v-layout justify-center>
                        <v-btn large flat outline class="subheading" color="primary" :to="`/${$i18n.locale}/forgetpass/`">{{ $t('login.forgot-pass') }}</v-btn>
                        <v-btn large block class="subheading" color="primary" @click="submit">{{ $t('login.login') }}</v-btn>
                    </v-layout>
                    <v-alert :value="message" type="warning">{{ message }}</v-alert>
                    <v-layout justify-center>
                        <v-btn large block class="subheading" color="success" :to="`/${$i18n.locale}/register/`">{{ $t('login.create-account') }}</v-btn>
                    </v-layout>
                    <v-subheader v-show="socialRule"><v-icon>groups</v-icon> &nbsp;{{ $t('login.social-login') }}</v-subheader>
                    <v-layout>
                        <google-login v-show="socialRule"></google-login><br/>
                    </v-layout>
                    <v-layout>
                        <line-login v-show="socialRule"></line-login>
                        <facebook-login v-show="socialRule"></facebook-login>
                    </v-layout>
                    <v-divider class="ma-2"></v-divider>
                    <v-layout justify-center>
                        <p class="pt-2 mb-0">
                            <a style="text-decoration:none" @click="routePage('privacy')">{{ $t('login.privacy-policy') }}</a> |
                            <a style="text-decoration:none" @click="routePage('terms')">{{ $t('login.terms-of-service') }}</a>
                        </p>
                    </v-layout>
                </v-form>
            </v-card-text>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
            <message-dialog
                :show.sync="msgDialog.show"
                :msg="msgDialog.msg"
                :primary-btn="msgDialog.primaryBtn"
            ></message-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import PasswordMixin from '@/mixins/PasswordMixin'
import { SideBtnType } from '@/store'
import i18n from '@/i18n'
import store, { HostUrl } from '@/store'
import FacebookLogin from '../components/FacebookLogin'
import GoogleLogin from "../components/GoogleLogin"
import LineLogin from '../components/LineLogin'
import { getAxiosConfig, PWA_TOKEN } from '@/utils/AuthService'
import { HOMETYPES } from '@/utils/utils'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import MessageDialog from '@/components/MessageDialog'
import MessageDialogControl from '@/mixins/MessageDialogControl'

export default {
    name: 'Login',
    components: { FacebookLogin, GoogleLogin, LineLogin, WaitingDialog, MessageDialog },
    mixins: [ PasswordMixin, WaitDialogControl, MessageDialogControl ],
    data() {
        return {
            headerRule: navigator.userAgent.indexOf('Build/MMB29') !== -1,
            valid: false,
            username: '',
            socialRule: store.getters.pageHome !== HOMETYPES.WASH && store.getters.pageHome !== HOMETYPES.TRAVELDOCS,
            loginRules: [
                v => {
                    if (!v) {
                        if (store.getters.pageHome === HOMETYPES.WASH || store.getters.pageHome === HOMETYPES.TRAVELDOCS) return i18n.t('login.rules.username-phone-rule')
                        else return i18n.t('login.rules.usernameRules')
                    }
                    else if (!(/^09\d{8}$/.test(v) && v.length === 10) && !(/.+@.+/.test(v))) {
                        if (store.getters.pageHome === HOMETYPES.WASH || store.getters.pageHome === HOMETYPES.TRAVELDOCS) return i18n.t('login.rules.correct-phone-format')
                        else return i18n.t('login.rules.correct-format')
                    }
                }
            ],
            passwordRules: [
                v => !!v || i18n.t('login.rules.passRules'),
                v => (v && v.length >= 6) || i18n.t('login.rules.minLengthErr'),
                v => (v && v.length <= 20) || i18n.t('login.rules.maxLengthErr')
            ],
            message: null,
            verifyCode: null
        }
    },
    methods: {
        loginHint() {
            if (store.getters.pageHome === HOMETYPES.WASH || store.getters.pageHome === HOMETYPES.TRAVELDOCS)
                return i18n.t('login.account-phone-only')
            else
                return i18n.t('login.account')
        },
        verifyHint() {
            if (store.getters.pageHome === HOMETYPES.WASH || store.getters.pageHome === HOMETYPES.TRAVELDOCS)
                return i18n.t('login.verify-code-phone-only')
            else
                return i18n.t('login.verify-code')
        },
        routePage(page) {
            if (page==='privacy') {
                this.$router.push(`/${i18n.locale}/about/privacy/`)
            } else {
                this.$router.push(`/${i18n.locale}/about/terms/`)
            }
        },
        submit() {
            if (this.$refs.form.validate()) {
                let uu = this.username
                if (this.verifyCode != null && !isNaN(this.verifyCode) && this.verifyCode.length == 6)
                    uu += ','+this.verifyCode.replace(" ", "")
                this.$store.dispatch('login', {
                    username: uu,
                    password: this.password
                })
            } else {
                this.showMsgDialog(i18n.t('login.login-fail-message'), i18n.t('login.confirm'))
            }
        },
        showLinePayWarning() {
            //由於首次進入頁面，router 會 driect 2次，因此添加 flag 在 localStorage 裡，以供提示視窗顯示判斷
            if (store.getters.showLinePayWarning) {
                this.message = i18n.t('login.close-login-tab') + i18n.t('login.continue-complete-payment')
            } else {
                if (localStorage.getItem('LinePayWarning')) {
                    this.message = i18n.t('login.close-login-tab') + i18n.t('login.continue-complete-payment')
                    localStorage.removeItem('LinePayWarning')
                } else {
                    this.message = null
                }
            }
        },
        checkUrl() {
            const vm = this;
            let confirmUrl = location.href
            if (confirmUrl.indexOf('?') !== -1 && confirmUrl.indexOf('code') !== -1 && confirmUrl.indexOf('state') !== -1) {
                let objectURL = new URL(confirmUrl)
                let params = objectURL.searchParams
                let urlParamsArray = []
                let info = {
                    code: '',
                    state: '',
                    friendship: false,
                    url: confirmUrl
                }
                for (let pair of params.entries()) {
                    let obj = {
                        key: pair[0],
                        value: pair[1]
                    }
                    urlParamsArray.push(obj)
                    if (obj.key === 'code') {
                        info.code = obj.value
                    }
                    if (obj.key === 'state') {
                        info.state = obj.value
                    }
                    if (obj.key === 'friendship_status_changed') {
                        if (obj.value === 'false') {
                            info.friendship = false 
                        } else {
                            info.friendship = true
                        }
                    }
                }
                if (urlParamsArray && urlParamsArray[0] && urlParamsArray[0].value) {
                    vm.showDialog(i18n.t('lineLogin.line-login'), "")
                    vm.line_login(info).then(
                        ()=>vm.closeDialog()
                    )
                }
            }
        },
        async line_login(info) {
            let out = null
            try {
                let payload = {
                    client_id: store.getters.clientId,
                    code: info.code,
                    locale: this.$i18n.locale,
                    env: 'bbox',
                    info: info,
                    browser: navigator.userAgent
                }
                let url = HostUrl + '/api/user/line_login'
                let response = await axios.post(url, payload, getAxiosConfig(PWA_TOKEN));
                if (response.data.code == 0) {
                    store.commit('setUserId', response.data.profile.id)
                    store.dispatch('changeUser', {
                        profile: response.data.profile,
                        token: response.data.token
                    })
                    out = false
                    store.dispatch('afterLogin')
                } else {
                    store.commit('setUserId', 0)
                    store.commit('setSnackBar', {
                        message: i18n.t('lineLogin.line-login-failed'),
                        color: 'error'
                    })
                    out = true
                }
            } catch (error) {
                store.commit('setSnackBar', {
                    message: i18n.t('lineLogin.line-login-failed'),
                    color: 'error'
                })
                out = true
            }
            if (out) {
                this.$router.push(`/${i18n.locale}/login`)
            }
        }
    },
    activated() {
        this.$store.commit('setTitle', 'login.nav-login');
        this.$store.commit('setSideBtnType', SideBtnType.Navi);
        let statepage = localStorage.getItem('lineState')
        if (this.$store.getters.pageHome == HOMETYPES.STORE)
            localStorage.setItem('lineState', 'storehome')
        else if (this.$store.getters.pageHome == HOMETYPES.TRAVELDOCS)
            localStorage.setItem('lineState', 'traveldocshome')
        else if (this.$store.getters.pageHome == HOMETYPES.WASH)
            localStorage.setItem('lineState', 'washhome')
        else if (statepage === 'storehome')
            this.$store.commit('setPageHome', HOMETYPES.STORE)
        else if (statepage === 'washhome')
            this.$store.commit('setPageHome', HOMETYPES.WASH)
        else if (statepage === 'traveldocshome')
            this.$store.commit('setPageHome', HOMETYPES.TRAVELDOCS)

        this.checkUrl()
        // Line Pay 提示框（先註解，已有transactionId login）
        // this.showLinePayWarning()
    }
}
</script>

<style scoped>
</style>
