import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import store from '@/store'
import { routePage } from '@/store'
import { upgrade } from '@/utils/Updater.js'
import i18n from '@/i18n'
import { LinePayType } from '@/components/payments/LinePay'
import { JKOPayType } from '@/components/payments/JKOPay'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: `/${i18n.locale}/`
        },
        {
            path: '/takeout',
            redirect: `/${i18n.locale}/`
        },
        {
            path: '/storehome',
            redirect: `/${i18n.locale}/storehome`
        },
        {
            path: '/washhome',
            redirect: `/${i18n.locale}/washhome`
        },
        {
            path: '/washhome/go',
            redirect: `/${i18n.locale}/washhome`
        },
        {
            path: '/traveldocshome',
            redirect: `/${i18n.locale}/traveldocshome`
        },
        {
            path: '/traveldocshome/go',
            redirect: `/${i18n.locale}/traveldocshome`
        },
        {
            path: '/:lang',
            component: {
                render(c) { return c('router-view') }
            },
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: Home,
                    props: (route) => ({query: route.query.q})
                },
                {
                    path: 'storehome',
                    name: 'storehome',
                    component: () => import('./views/StoreHome.vue')
                },
                {
                    path: 'washhome',
                    name: 'washhome',
                    component: () => import('./views/WashHome.vue')
                },
                {
                    path: 'traveldocshome',
                    name: 'traveldocshome',
                    component: () => import('./views/TravelDocsHome.vue')
                },
                {
                    path: 'about',
                    name: 'about',
                    component: () => import('./views/About.vue')
                },
                {
                    path: 'login',
                    name: 'login',
                    component: () => import('./views/Login.vue')
                },
                {
                    path: 'register',
                    name: 'register',
                    component: () => import('./views/Register.vue')
                },
                {
                    path: 'forgetpass',
                    name: 'forgetpass',
                    component: () => import('./views/ForgetPassword.vue')
                },
                {
                    path: 'password',
                    name: 'password',
                    component: () => import('./views/ChangePassword.vue'),
                    props: (route) => ({pass: route.query.p})
                },
                {
                    path: 'emailauth/:data',
                    name: 'emailauth',
                    component: () => import('./views/EmailAuth.vue')
                },
                {
                    path: 'menu',
                    name: 'menu',
                    component: () => import('./views/Menu.vue')
                },
                {
                    path: 'changename',
                    name: 'changename',
                    component: () => import('./views/ChangeName.vue')
                },
                {
                    path: 'changephone',
                    name: 'changephone',
                    component: () => import('./views/ChangePhone.vue')
                },
                {
                    path: 'changeemail',
                    name: 'changeemail',
                    component: () => import('./views/ChangeEmail.vue')
                },
                {
                    path: 'updateinvoice',
                    name: 'updateinvoice',
                    component: () => import('./views/UpdateInvoice.vue')
                },
                {
                    path: 'setting',
                    name: 'setting',
                    component: () => import('./views/Setting.vue')
                },
                {
                    path: 'takeout',
                    name: 'takeout',
                    component: () => import('./views/Takeout.vue')
                },
                {
                    path: 'takeout-change',
                    name: 'takeout-change',
                    component: () => import('./views/TakeoutChange.vue')
                },
                {
                    path: 'pending-record',
                    name: 'pending-record',
                    component: () => import('./views/PendingRecord.vue')
                },
                {
                    path: 'putin',
                    name: 'putin',
                    component: () => import('./views/Putin.vue'),
                    props: true
                },
                {
                    path: 'payments',
                    name: 'payments',
                    component: () => import('./views/PaymentHistory.vue'),
                },
                {
                    path: 'payments/:id',
                    name: 'paymentDetail',
                    component: () => import('./views/PaymentDetail.vue'),
                    props: true
                },
                {
                    path: 'staff/menu',
                    name: 'staffMenu',
                    component: () => import('./views/staff/Menu.vue')
                },
                {
                    path: 'staff/putin',
                    name: 'staffPutin',
                    component: () => import('./views/staff/Putin.vue'),
                    props: true
                },
                {
                    path: 'staff/box-setting',
                    name: 'boxSetting',
                    component: () => import('./views/staff/BoxSetting.vue')
                },
                {
                    path: 'staff/box-reopen',
                    name: 'boxReopen',
                    component: () => import('./views/staff/BoxReopen.vue')
                },
                {
                    path: 'staff/door-check',
                    name: 'doorCheck',
                    component: () => import('./views/staff/DoorCheck.vue')
                },
                {
                    path: 'logistics/menu',
                    name: 'logisticsMenu',
                    component: () => import('./views/logistics/Menu.vue')
                },
                {
                    path: 'logistics/putin',
                    name: 'logisticsPutin',
                    component: () => import('./views/logistics/Putin.vue')
                },
                {
                    path: 'logistics/client-retrieve',
                    name: 'logisticsRetrieve',
                    component: () => import('./views/logistics/Retrieve.vue')
                },
                {
                    path: 'logistics/clerk-retrieve',
                    name: 'logisticsClerkRetrieve',
                    component: () => import('./views/logistics/ClerkRetrieve.vue')
                },
                {
                    path: 'logistics/lock-door',
                    name: 'logisticsLockDoor',
                    component: () => import('./views/logistics/LockDoor.vue')
                },
                {
                    path: 'logistics/pending-release',
                    name: 'logisticsPendingRelease',
                    component: () => import('./views/logistics/PendingRelease.vue')
                },
                {
                    path: 'storehost/takeout',
                    name: 'Buy',
                    component: () => import('./views/storehost/Takeout.vue')
                },
                {
                    path: 'storehost/menu',
                    name: 'storehostMenu',
                    component: () => import('./views/storehost/Menu.vue')
                },
                {
                    path: 'storehost/putin',
                    name: 'storehostPutin',
                    component: () => import('./views/storehost/Putin.vue')
                },
                {
                    path: 'storehost/retrieve',
                    name: 'storehostRetrieve',
                    component: () => import('./views/storehost/Retrieve.vue')
                },
                {
                    path: 'washhost/putin',
                    name: 'washInBox',
                    component: () => import('./views/washhost/ClientPutin.vue')
                },
                {
                    path: 'washhost/takeout',
                    name: 'washOutBox',
                    component: () => import('./views/washhost/ClientTakeout.vue')
                },
                {
                    path: 'washhost/menu',
                    name: 'washhostMenu',
                    component: () => import('./views/washhost/Menu.vue')
                },
                {
                    path: 'washhost/clerk-takeout',
                    name: 'washClerkTakeout',
                    component: () => import('./views/washhost/ClerkTakeout.vue')
                },
                {
                    path: 'washhost/clerk-putin',
                    name: 'washClerkPutin',
                    component: () => import('./views/washhost/ClerkPutin.vue')
                },
                {
                    path: 'washhost/clerk-print-list',
                    name: 'washClerkPrintList',
                    component: () => import('./views/washhost/ClerkPrinterList.vue')
                },
                {
                    path: 'washhost/clerk-revise-putin',
                    name: 'washRevisePutin',
                    component: () => import('./views/washhost/RevisePutin.vue')
                },
                {
                    path: 'washhost/clerk-remote',
                    name: 'washRemote',
                    component: () => import('./views/washhost/DoorCheck.vue')
                },
                {
                    path: 'docshost/putin',
                    name: 'docsInBox',
                    component: () => import('./views/docshost/ClientPutin.vue')
                },
                {
                    path: 'docshost/takeout',
                    name: 'docsOutBox',
                    component: () => import('./views/docshost/ClientTakeout.vue')
                },
                {
                    path: 'docshost/menu',
                    name: 'docshostMenu',
                    component: () => import('./views/docshost/Menu.vue')
                },
                {
                    path: 'docshost/clerk-takeout',
                    name: 'docsClerkTakeout',
                    component: () => import('./views/docshost/ClerkTakeout.vue')
                },
                {
                    path: 'docshost/clerk-putin',
                    name: 'docsClerkPutin',
                    component: () => import('./views/docshost/ClerkPutin.vue')
                },
                {
                    path: 'docshost/clerk-package-list',
                    name: 'docsClerkPackageList',
                    component: () => import('./views/docshost/ClerkPackageList.vue')
                },
                {
                    path: 'docshost/client-package-list',
                    name: 'docsClientPackageList',
                    component: () => import('./views/docshost/ClientPackageList.vue')
                },
                {
                    path: 'docshost/client-package-out-list',
                    name: 'docsClientPackageFinList',
                    component: () => import('./views/docshost/ClientPackageFinList.vue')
                },
                {
                    path: 'detail',
                    name: 'detail',
                    component: () => import('./components/payments/ElectronicMoneyDetail.vue')
                },
                {
                    path: 'about/terms',
                    name: 'terms',
                    component: () => import('./views/TermsOfUse.vue')
                },
                {
                    path: 'about/privacy',
                    name: 'privacy',
                    component: () => import('./views/PrivacyPolicy.vue')
                },
                {
                    path: 'about/company',
                    name: 'company',
                    component: () => import('./views/Company.vue')
                },
                {
                    path: 'jkopay',
                    name: 'jkopay',
                    component: () => import('./views/JKOPayWaitPage.vue')
                },
                {
                    path: 'linepay',
                    name: 'linepay',
                    component: () => import('./views/LinePayWaitPage.vue')
                },
                {
                    path: 'storehost/jkopay',
                    name: 'jkopay',
                    component: () => import('./views/JKOPayWaitPage.vue')
                },
                {
                    path: 'storehost/linepay',
                    name: 'linepay',
                    component: () => import('./views/LinePayWaitPage.vue')
                },
                {
                    path: 'washhost/jkopay',
                    name: 'jkopay',
                    component: () => import('./views/JKOPayWaitPage.vue')
                },
                {
                    path: 'washhost/linepay',
                    name: 'linepay',
                    component: () => import('./views/LinePayWaitPage.vue')
                }
                // {
                //     path: 'paypal',
                //     name: 'paypal',
                //     component: () => import('./views/PaypalWaitingPage.vue')
                // }
            ]
        },
    ]
})

const AllowUnauthRouteNames = [
    'home', 'storehome', 'washhome', 'traveldocshome', 'login', 'confirmAuth', 'forgetpass', 'register', 'password',
    'emailauth', 'about', 'terms', 'privacy', 'company'
]

import { HOMETYPES } from '@/utils/utils'
router.beforeResolve((to, from, next) => {
    console.log('start')
    let fullurl = location.href
    console.log('before:'+fullurl)
    if (fullurl.indexOf(`docs`) !== -1) {
        console.log('set traveldocshome')
        store.commit('setPageHome', HOMETYPES.TRAVELDOCS)
    } else if (fullurl.indexOf(`wash`) !== -1) {
        console.log('set washhome')
        store.commit('setPageHome', HOMETYPES.WASH)
    } else if (fullurl.indexOf(`store`) !== -1) {
        console.log('set storehome')
        store.commit('setPageHome', HOMETYPES.STORE)
    }
    store.commit('setFromPath', from.path)
    upgrade(); // check update before every page change
    console.log('upgrade from:'+from.path)
    store.dispatch('getUserProfile')
    if (!AllowUnauthRouteNames.includes(to.name)) {
        if (store.getters.user.id === 0) {
            if (location.href.indexOf('?') !== -1 & location.href.indexOf('transactionId') !== -1) {
                store.commit('setShowLinePayWarning', true)
                localStorage.setItem('LinePayWarning', '1')
                let paymentType = LinePayType
                if (location.href.slice(location.href.indexOf("?") + 1, location.href.indexOf("=")) === "jko_transactionId") {
                    paymentType = JKOPayType
                }
                let transactionId = location.href.slice(location.href.indexOf("=") + 1)
                store.dispatch('transactionIdLogin', {
                    transactionId: transactionId,
                    payment_type: paymentType,
                    client_id: store.getters.clientId
                })
                    .then(() => {
                        next()
                    })
                    .catch(() => {
                        store.commit('setIsFromLinePay', true)
                        next(`/${i18n.locale}/login`)
                    })
                return
            } else {
                store.commit('setLoginRedirect', to.fullPath)
                next(`/${i18n.locale}/login`)
                return
            }
        }
    }
    next();
})

export function redirectLogin() {
    let current = router.currentRoute
    if (!AllowUnauthRouteNames.includes(current.name)) {
        router.push(`/${i18n.locale}/login`)
    }
    else {
        routePage(store.getters.pageHome)
    }
}

const LoadChunkFailedPattern = /loading chunk .+ failed/gi
router.onError(error => {
    // issue #36 Load chunk failed 表示 browser 仍 cache 舊版, 告知使用者已更新版本, 刷新頁面即可完成更新
    if (LoadChunkFailedPattern.test(error.message)) store.commit('setUpdateAvailable', true)
})

export default router