<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <v-layout justify-center fill-height>
                    <v-flex xs12 sm8 md4>
                        <v-card-text>
                            <v-form ref="form" v-model="valid">
                                <v-text-field outline clearable prepend-icon="kitchen" name="boxId" label="櫃體編號" type="tel"
                                              v-model="boxId" mask="######" :rules="boxIdRules" data-cy="boxIdField" required>
                                </v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn large block color="primary" :disabled="!valid" @click="setBoxID()">{{ $t('doorCheck.send') }}</v-btn>
                        </v-card-actions>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-1">
                <v-btn v-if="boxId.length==6" large block color="green" @click="doorsStatus()"><b>{{ $t('doorCheck.door-status') }}</b></v-btn>
                <v-layout justify-center>
                    <v-flex xs12 sm10 md8 lg8>
                        <door-inspector
                            :enable="stepNo === 2"
                            :box-id="boxId"
                            :door-id.sync="doorId"
                            @select="selectDoorByStaff"
                            @back="preStep"
                        ></door-inspector>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-2">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <v-card flat>
                            <v-card-title class="subheading">
                                <h2>{{ $t('doorCheck.door-info') }}</h2>
                            </v-card-title>
                            <v-card-text class="subheading">
                                <ul v-for="(item, index) in detail" :key=index class="nobull">
                                    <li :class=item.color><b>{{ item.title }}</b>&nbsp;&nbsp;{{ item.content }}</li>
                                </ul>
                            </v-card-text>
                        </v-card>
                        <div align="center">
                            <v-btn block v-if="isDisabled" color="blue accent-1" v-show="(check_mode==1)">
                                {{ checkMsg(1) }}
                                <v-progress-circular indeterminate size="20" width="2" color="white"></v-progress-circular>
                            </v-btn>
                            <v-btn v-else color="light-blue accent-2" @click="checkDoor(boxId, 1)">
                                <b>{{ checkMsg(1) }}</b>
                            </v-btn>
                            <v-btn block v-if="isDisabled" color="success" v-show="(check_mode==0)">
                                {{ checkMsg(0) }}
                                <v-progress-circular indeterminate size="20" width="2" color="white"></v-progress-circular>
                            </v-btn>
                            <v-btn dark v-else color="green darken-4" @click="checkDoor(boxId, 0)">
                                <b>{{ checkMsg(0) }}</b>
                            </v-btn>
                        </div>
                        <p></p>
                        <div>
                            <v-btn block class="subheading" v-if="canReset" color="success">
                                {{ resetMsg() }}
                                <v-progress-circular indeterminate size="20" width="2" color="white"></v-progress-circular>
                            </v-btn>
                            <v-btn block class="subheading" v-else-if="showReset" color="red" @click="resetUrl">
                                {{ resetMsg() }}
                            </v-btn>
                        </div>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-btn flat large block color="primary" @click="conti">
                        <v-icon>arrow_back_ios</v-icon>{{$t('doorCheck.back')}}
                    </v-btn>
                    <v-btn block class="subheading" color="primary" @click="leave()">{{ $t('doorCheck.leave') }}</v-btn>
                </v-layout>
            </v-stepper-content>
        </v-stepper-items>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store, { HostUrl } from "@/store"
import CheckMixin from '@/mixins/CheckMixin'
import CheckResultWatcher from '@/mixins/CheckResultWatcher'
import ControlResultWatcher from '@/mixins/ControlResultWatcher'
import i18n from '@/i18n'
import { MQTT_ACTION, TRANS_TYPE, publish } from '@/utils/MQTTClient'
import { getAxiosConfig } from '@/utils/AuthService'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import { RESET_SERVER_TIMEOUT, CHECK_DOOR_TIMEOUT, RESET_DOOR_TIMEOUT } from '@/utils/utils'

const DOOR_CONTROL_MODE = {
    OPEN: 0,
    DOOR_STATUS: 4
}

export default {
    name: 'washRemote',
    components: { WaitingDialog },
    mixins: [CheckMixin, CheckResultWatcher, ControlResultWatcher, WaitDialogControl],
    data() {
        return {
            titles: ['輸入櫃號', i18n.t('putIn.select-cell'), i18n.t('doorCheck.door-check')],
            valid: false,
            boxId: '',
            boxIdRules: [
                v => !!v || i18n.t('doorCheck.input-box-id'),
                v => /\d{6}/.test(v) || i18n.t('doorCheck.available-box-id')
            ],
            info: null,
            amount: 0,
            isDisabled: false,
            timeout: null,
            isAvailable: true,
            canReset: false,
            showReset: false,
            check_mode: -1,
            controlMode: 0
        }
    },
    watch: {
        doorCheckResult() {
            this.closeDialog()
            const resultObj = this.doorCheckResult
            const wrong_slave = localStorage.getItem('slave')
            console.log('[get check result]')
            if (wrong_slave.length > 0) {
                store.commit('setSnackBar', { message: '面對櫃體左邊 第 ' + wrong_slave + ' 個櫃子的中控版異常', color: 'error' })
            } else {
                let controlOK = 0
                let cell
                let door
                for (cell in resultObj) {
                    door = cell
                    if ((resultObj[cell] === 0) || ((resultObj[cell] & 1) == 1))
                        controlOK = 0
                    else if ((resultObj[cell] & 6) == 6)
                        controlOK = 2
                    else
                        controlOK = 1
                }
                this.isDisabled = false
                let check_mode = this.check_mode
                if (controlOK == 1 && check_mode == 0)
                    store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + door + i18n.t('doorCheck.success-open-cell'), color: 'success' })
                else if (check_mode == 1 && (controlOK == 1 || controlOK == 0))
                    store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + door + i18n.t('doorCheck.success-close-cell'), color: 'success' })
                else if (controlOK == 2)
                    store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + door + i18n.t('doorCheck.success-check-cell') + '*' + i18n.t('doorCheck.close-cell') + '*', color: 'success' })
                else
                    store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + this.doorId + i18n.t('doorCheck.fail-check-cell'), color: 'error' })
            }
            this.check_mode = -1
        },
        controlResult() {
            this.closeDialog()
            const resultObj = this.controlResult
            const wrong_slave = localStorage.getItem('slave')
            console.log(resultObj)
            if (this.controlMode === DOOR_CONTROL_MODE.DOOR_STATUS) {
                if (wrong_slave.length > 0) {
                    store.commit('setSnackBar', { message: '面對櫃體左邊 第 ' + wrong_slave + ' 個櫃子的中控版異常', color: 'error' })
                } else {
                    let openArray = []
                    let closeArray = []
                    let cell
                    for( cell in resultObj ) {
                        if(resultObj[cell] == DOOR_CONTROL_MODE.OPEN)
                            openArray.push(cell)
                        else
                            closeArray.push(cell)
                    }
                    if (openArray.length > 0 && closeArray.length > 0)
                        store.commit('setSnackBar', { message: openArray.length +'個開啟格' + openArray.toString() + '；' + closeArray.length + '個關上格' + closeArray.toString(), color: 'success' })
                    else if (openArray.length === 0 && closeArray.length > 0)
                        store.commit('setSnackBar', { message: closeArray.length + '個關上格' + closeArray.toString(), color: 'success' })
                    else if (openArray.length > 0 && closeArray.length === 0)
                        store.commit('setSnackBar', { message: openArray.length +'個開啟格' + openArray.toString(), color: 'success' })
                    else
                        store.commit('setSnackBar', { message: '格門狀態無回應', color: 'error' })
                }
                this.controlMode = 0
            } else {
                if (resultObj[this.track_no] == this.doorId) {
                    //store.commit('setSnackBar', { message: i18n.t('doorCheck.door') + DoorId + ' ' + i18n.t('doorCheck.reset-rpi-cell-ok') , color: 'light-green darken-2' })
                    this.rechooseDoor(RESET_SERVER_TIMEOUT)
                }
            }
        }
    },
    computed: {
        detail() {
            let tag = "green--text"
            let takePhone = ''
            let putin_time = ''
            let pay_method = ''
            let ptag = "black--text"
            if (this.doorInfo) {
                takePhone = this.doorInfo.takeout_phone
                pay_method = this.doorInfo.pay_name
                console.log(takePhone[0]+takePhone[1])
                putin_time = this.doorInfo.putin_time
                if (takePhone === '') {
                    takePhone = '-'
                } else if (takePhone.length === 13 && takePhone.indexOf('[友]') !== -1) {
                    takePhone = i18n.t('doorCheck.take-user-mark')+takePhone.split(']')[1]
                    ptag = "brown--text"
                }
                if (pay_method == '') pay_method = '--'
                if (putin_time == '') putin_time = '-'
                this.getAmounts()
                if (this.isAvailable == false)
                    tag = "red--text"
            }
            return [
                { title: i18n.t('putIn.cabinet-number'), content: this.boxId, color: "black--text"},
                { title: i18n.t('putIn.cell-number'), content: this.doorId, color: "black--text" },
                { title: i18n.t('doorCheck.info'), content: this.info, color: tag },
                { title: i18n.t('doorCheck.takeout-user'), content: takePhone, color: ptag},
                { title: i18n.t('doorCheck.takeout-amount'), content: this.amount, color: "black--text" },
                { title: i18n.t('doorCheck.pay-method'), content: pay_method, color: "blue--text" },
                { title: i18n.t('doorCheck.putin_time'), content: putin_time, color: "black--text" },
                { title: i18n.t('doorCheck.open-mode'), content: i18n.t('doorCheck.open-mode-detail'), color: "black--text" },
                { title: i18n.t('doorCheck.close-mode'), content: i18n.t('doorCheck.close-mode-detail'), color: "black--text" }
            ]
        }
    },
    methods: {
        init() {
            store.commit('initDoorCheck')
            store.commit('setTitle', this.titles[0])
            store.commit('setTargetBoxId', '')
            this.valid = false,
            this.boxId = ''
            this.stepNo = 1
            this.doorId = 0
            this.doorInfo = null
            this.amount = 0
            this.isDisabled = false
            this.isAvailable = false
            this.canReset = false
            this.showReset = false
            this.track_no = ''
            this.check_mode = -1
        },
        setBoxID() {
            if (this.valid === true && this.boxId.length === 6) {
                store.commit('setTargetBoxId', this.boxId);
            }
        },
        box_pass_rule() {
            const box_owner = store.getters.user.box_owner
            if (this.boxId.length === 6 && null != box_owner && !box_owner.includes(this.boxId)) {
                store.commit('setSnackBar', {message: i18n.t('doorSelector.cabinet-not-set') ,color: 'error'});
                this.leave()
                return false;
            }
            return true;
        },
        doorsStatus() {
            if (this.box_pass_rule()) {
                this.showDialog('格門狀態中', '格門狀態檢視失敗，請再嘗試一次', 50000)
                this.setBoxID()
                //console.log(store.getters.targetBoxId)
                this.controlMode = DOOR_CONTROL_MODE.DOOR_STATUS
                let topic = 'box/' + this.boxId + '/doors/status'
                publish(topic, {
                    user_id: store.getters.user.id,
                    client_id: store.getters.clientId
                }, 1)
            }
        },
        selectDoorByStaff(door, rate) {
            if (this.box_pass_rule() === false) return
            this.selectDoor(door, rate)
            this.isDisabled = false
            if (!store.getters.targetBoxId && this.valid === true && this.boxId.length === 6) {
                this.camera = 'off'
            }
            store.commit('setTargetBoxId', this.boxId)
            if(this.doorId && this.doorId != 0) {
                let status = this.doorInfo['status']
                let ava = this.doorInfo['is_available']
                if (status ===7 || status === 263 || status == 327) {
                    this.showReset = false
                } else if (ava === false) {
                    this.showReset = true
                }
                console.log('showReset:'+this.showReset+', status:'+status+', ava:'+this.isAvailable)
            }
            this.nextStep()
        },
        getAmounts(){
            this.amount = 0
            if ((this.doorInfo['status'] == MQTT_ACTION.PUTIN_FINISH) || (this.doorInfo['status'] == (MQTT_ACTION.PUTIN_FINISH | MQTT_ACTION.NOTIFY))) {
                if (this.doorInfo['trans_type'] == TRANS_TYPE.LEAVE) {
                    this.info = i18n.t('doorCheck.putin')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.DELIVERY){
                    this.info = i18n.t('doorCheck.delivery')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.LONG_LEAVE){
                    this.info = i18n.t('doorCheck.long-leave')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.WASHCLIENT){
                    this.info = i18n.t('doorCheck.wash-putin')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.WASHCLERK){
                    this.info = i18n.t('doorCheck.washclerk-putin')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.APICLIENT){
                    this.info = i18n.t('doorCheck.API-putin')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.DOCSCLIENT){
                    this.info = i18n.t('doorCheck.docs-putin')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.DOCSCLERK){
                    this.info = i18n.t('doorCheck.docsclerk-putin')
                    this.amount = this.doorInfo['amount']
                }
                else {//TRANS_TYPE.STOREHOST
                    this.info = this.doorInfo['product_name'] + i18n.t('doorCheck.store-goods')
                    this.amount = this.doorInfo['fee']
                }
            } else if (this.doorInfo['is_available'] === false) {
                if ((this.doorInfo['status'] & MQTT_ACTION.CONTROL_ERROR) == MQTT_ACTION.CONTROL_ERROR) {
                    this.info = i18n.t('doorCheck.putin-door-error')
                } else if (((this.doorInfo['status'] & MQTT_ACTION.TAKEOUT_ACTION) != MQTT_ACTION.TAKEOUT_ACTION) && ((this.doorInfo['status'] & MQTT_ACTION.PUTIN_FINISH) == MQTT_ACTION.PUTIN_FINISH)){
                    this.info = i18n.t('doorCheck.takeout-door-error')
                } else
                    this.info = i18n.t('doorCheck.other-door-error')
            } else {
                this.info = i18n.t('doorCheck.door-can-use')
            }
            this.isAvailable = this.doorInfo['is_available']
            this.track_no = this.doorInfo['track_no']
        },
        checkDoor(box_id, check_mode) {
            this.check_mode = check_mode
            this.isDisabled = true
            var topic = `box/${box_id}/doors/check`
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId,
                door_ids: [this.doorId],
                door_close: check_mode
            }, 1)

            this.timeout = setTimeout(this.stopCheckDoor, CHECK_DOOR_TIMEOUT)
        },
        stopCheckDoor() {
            this.check_mode = -1
            this.isDisabled = false
            clearTimeout(this.timeout)
            store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + this.doorId + i18n.t('doorCheck.fail-check-cell-timeout'), color: 'error' })
        },
        checkMsg(check_mode) {
            if (this.isDisabled == true) {
                if (check_mode === 0)
                    return i18n.t('doorCheck.wait-check-open-door')
                else
                    return i18n.t('doorCheck.wait-check-close-door')
            } else {
                if (check_mode === 0)
                    return i18n.t('doorCheck.check-open-door')
                else
                    return i18n.t('doorCheck.check-close-door')
            }
        },
        rechooseDoor(timeout){
            let time = timeout
            if (time === 0)
                time = timeout + 5000
            this.showDialog(i18n.t(`doorCheck.reset-rpi-cell-ok`), '', time)
            setTimeout(() => {
                this.canReset = false
                this.doorInfo = null
                store.commit('setSnackBar', { message: i18n.t('doorCheck.finish-reset-door') + i18n.t('doorCheck.door') + this.doorId, color: 'success' })
                this.doorId = 0
                this.setStepNo(2)
                this.closeDialog()
            }, time);
        },
        conti(){
            this.doorId = 0
            this.doorInfo = null
            this.canReset = false
            clearTimeout(this.timeout)
            this.check_mode = -1
            this.setStepNo(2)
        },
        resetMsg() {
            if (this.canReset == true) {
                return i18n.t('doorCheck.wait-reset-door')
            } else {
                return i18n.t('doorCheck.reset-door')
            }
        },
        leave() {
            this.init()
            this.$router.push(`/${i18n.locale}/washhost/menu`)
        },
        resetUrl() {
            if (this.isAvailable == false && this.box_pass_rule()) {
                this.canReset = true
                let payload = {
                    client_id: store.getters.clientId,
                    door_id: this.doorId,
                    track_no: this.doorInfo['track_no']
                }
                this.showDialog(i18n.t(`doorCheck.wait-reset-door`), i18n.t(`doorCheck.fail-reset-cell`), RESET_DOOR_TIMEOUT)
                let url = HostUrl + `/api/staff/` + this.boxId + `/reset/control`
                let self = this
                axios.post(url, payload, getAxiosConfig()).then(function (response) {
                    if (response.data.code == 0) {
                        if (response.data.packages && response.data.packages['door_control'] == false) {
                            self.closeDialog()
                            self.rechooseDoor(0)
                        }
                        else if (!response.data.packages) {
                            self.closeDialog()
                            self.canReset = false
                            store.commit('setSnackBar', { message: i18n.t('doorCheck.transaction-package-fail'), color: 'error' })
                        }
                    }
                    else {
                        self.closeDialog()
                        self.canReset = false
                        store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                    }
                }).catch(function () {
                    self.closeDialog()
                    self.canReset = false
                    store.commit('setSnackBar', { message: i18n.t('doorCheck.transaction-fail'), color: 'error' })
                })
            }
        }
    },
    deactivated() {
        clearTimeout(this.timeout)
        this.init()
    }
};
</script>

<style scoped>
</style>
