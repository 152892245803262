<template>
    <v-stepper v-model="stepNo">
        <v-stepper-header>
            <template v-for="(title, index) in titles">
                <v-stepper-step :complete="stepNo > index + 1" :step="index + 1" :key="'step'+index">{{title}}</v-stepper-step>
                <v-divider v-if="index !== titles.length - 1" :key="'devider'+index"></v-divider>
            </template>
        </v-stepper-header>
        <v-stepper-items>
            <v-stepper-content step="1">
                <QRReader :camera="camera" @query="onQuery"></QRReader>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-1">
                <v-layout justify-center v-show="box_pass_rule()">
                    <v-flex xs12 sm10 md8 lg8>
                        <door-inspector
                            :enable="stepNo === 2"
                            :box-id="boxId"
                            :door-id.sync="doorId"
                            @select="selectDoorByStaff"
                            @back="preStep"
                        ></door-inspector>
                    </v-flex>
                </v-layout>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-2">
                <v-layout justify-center>
                    <v-flex xs12 sm8 md6>
                        <v-card flat>
                            <v-card-title class="subheading">
                                <h2>{{ $t('doorCheck.door-info') }}</h2>
                            </v-card-title>
                            <v-card-text class="subheading">
                                <ul v-for="(item, index) in detail" :key=index class="nobull">
                                    <li :class=item.color><b>{{ item.title }}</b>&nbsp;&nbsp;{{ item.content }}</li>
                                </ul>
                            </v-card-text>
                        </v-card>
                        <div align="center">
                            <v-btn block v-if="isDisabled" color="blue accent-1" v-show="(check_mode==1)">
                                {{ checkMsg(1) }}
                                <v-progress-circular indeterminate size="20" width="2" color="white"></v-progress-circular>
                            </v-btn>
                            <v-btn v-else color="light-blue accent-2" @click="checkDoor(boxId, 1)">
                                <b>{{ checkMsg(1) }}</b>
                            </v-btn>
                            <v-btn block v-if="isDisabled" color="success" v-show="(check_mode==0)">
                                {{ checkMsg(0) }}
                                <v-progress-circular indeterminate size="20" width="2" color="white"></v-progress-circular>
                            </v-btn>
                            <v-btn dark v-else color="green darken-4" @click="checkDoor(boxId, 0)">
                                <b>{{ checkMsg(0) }}</b>
                            </v-btn>
                        </div>
                        <p></p>
                        <div>
                            <v-btn block class="subheading" color="red accent-2" v-show="(check_mode==-1)" v-if="isAvailable" @click="lockUrl"><v-icon left>lock</v-icon>{{ $t('lockDoor.lock') }}</v-btn>
                            <v-btn block class="subheading" color="red accent-2" v-show="(check_mode==-1)" v-else disabled @click="lockUrl"><v-icon left>lock</v-icon>{{ $t('lockDoor.lock') }}</v-btn>
                        </div>
                    </v-flex>
                </v-layout>
                <v-layout justify-center>
                    <v-btn flat large block color="primary" @click="conti">
                        <v-icon>arrow_back_ios</v-icon>{{$t('lockDoor.back')}}
                    </v-btn>
                    <v-btn block class="subheading" color="primary" @click="leave()">{{ $t('lockDoor.leave') }}</v-btn>
                </v-layout>
            </v-stepper-content>
        </v-stepper-items>
        <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
        <message-dialog
            :show.sync="msgDialog.show"
            :msg="msgDialog.msg"
            :primary-btn="msgDialog.primaryBtn"
            :secondary-btn="msgDialog.secondaryBtn"
            @primary="closeMsgDialogFunc"
        ></message-dialog>
    </v-stepper>
</template>

<script>
import axios from 'axios'
import store, { HostUrl } from "@/store"
import CheckMixin from '@/mixins/CheckMixin'
import CheckResultWatcher from '@/mixins/CheckResultWatcher'
import i18n from '@/i18n'
import { MQTT_ACTION, TRANS_TYPE, publish } from '@/utils/MQTTClient'
import { ASSIGN_VIEW, getAxiosConfig } from '@/utils/AuthService'
import WaitingDialog from '@/components/WaitingDialog'
import MessageDialog from '@/components/MessageDialog'
import MessageDialogControl from '@/mixins/MessageDialogControl'
import MQTTMessageWatcher from '@/mixins/MQTTMessageWatcher'
import { CHECK_DOOR_TIMEOUT, RESET_DOOR_TIMEOUT } from '@/utils/utils'

export default {
    name: 'LogisticsLockDoor',
    components: { WaitingDialog, MessageDialog },
    mixins: [CheckMixin, CheckResultWatcher, MessageDialogControl, MQTTMessageWatcher],
    data() {
        return {
            titles: [i18n.t('putIn.scan-code'), i18n.t('putIn.select-cell'), i18n.t('lockDoor.door-lock')],
            closeMsgDialogFunc: () => {},
            info: null,
            amount: 0,
            isDisabled: false,
            timeout: null,
            isAvailable: true,
            canLock: true,
            check_mode: -1
        }
    },
    watch: {
        doorCheckResult() {
            this.closeDialog()
            const resultObj = this.doorCheckResult
            let controlOK = 0
            let cell
            let door
            for (cell in resultObj) {
                door = cell
                if ((resultObj[cell] === 0) || ((resultObj[cell] & 1) == 1))
                    controlOK = 0
                else if ((resultObj[cell] & 6) == 6)
                    controlOK = 2
                else
                    controlOK = 1
            }
            this.isDisabled = false
            let check_mode = this.check_mode
            clearTimeout(this.timeout)
            if (controlOK == 1 && check_mode == 0)
                store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + door + i18n.t('doorCheck.success-open-cell'), color: 'success' })
            else if (controlOK == 1 && check_mode == 1)
                store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + door + i18n.t('doorCheck.success-close-cell'), color: 'success' })
            else if (controlOK == 2)
                store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + door + i18n.t('doorCheck.success-check-cell') + '*' + i18n.t('doorCheck.close-cell') + '*', color: 'success' })
            else
                store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + this.doorId + i18n.t('doorCheck.fail-check-cell'), color: 'error' })
            this.check_mode = -1
        }
    },
    computed: {
        detail() {
            let tag = "green--text"
            if (this.doorInfo)
                this.getAmounts()
                if (this.isAvailable == false)
                    tag = "red--text"
            return [
                { title: i18n.t('putIn.cabinet-number'), content: this.boxId, color: "black--text"},
                { title: i18n.t('putIn.cell-number'), content: this.doorId, color: "black--text" },
                { title: i18n.t('doorCheck.info'), content: this.info, color: tag },
                { title: i18n.t('doorCheck.takeout-amount'), content: this.amount, color: "black--text" }
            ]
        }
    },
    methods: {
        init() {
            store.commit('initDoorLock')
            store.commit('setFromViews', ASSIGN_VIEW.LOCK)
            store.commit('setTitle', i18n.t('putIn.scan-code'))
            store.commit('setTargetBoxId', '')
            this.boxId = ''
            this.stepNo = 1
            this.doorId = 0
            this.doorInfo = null
            this.amount = 0
            this.isDisabled = false
            this.isAvailable = false
            this.canLock = true
            this.track_no = ''
            this.check_mode = -1
        },
        box_pass_rule() {
            const box_owner = store.getters.user.box_owner
            if (this.boxId.length === 6 && null != box_owner && !box_owner.includes(this.boxId)) {
                store.commit('setSnackBar', {message: i18n.t('doorSelector.cabinet-not-set') ,color: 'error'});
                this.leave()
                return false;
            }
            return true;
        },
        selectDoorByStaff(door, rate) {
            this.selectDoor(door, rate)
            this.isDisabled = false
            this.nextStep()
        },
        getAmounts(){
            this.amount = 0
            this.canLock = true
            if ((this.doorInfo['status'] == MQTT_ACTION.PUTIN_FINISH) || (this.doorInfo['status'] == (MQTT_ACTION.PUTIN_FINISH | MQTT_ACTION.NOTIFY))) {
                if (this.doorInfo['trans_type'] == TRANS_TYPE.LEAVE) {
                    this.info = i18n.t('doorCheck.putin')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.DELIVERY){
                    this.info = i18n.t('doorCheck.delivery')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.LONG_LEAVE){
                    this.info = i18n.t('doorCheck.long-leave')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.WASHCLIENT){
                    this.info = i18n.t('doorCheck.wash-putin')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.WASHCLERK){
                    this.info = i18n.t('doorCheck.washclerk-putin')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.APICLIENT){
                    this.info = i18n.t('doorCheck.API-putin')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.DOCSCLIENT){
                    this.info = i18n.t('doorCheck.docs-putin')
                    this.amount = this.doorInfo['amount']
                }
                else if (this.doorInfo['trans_type'] == TRANS_TYPE.DOCSCLERK){
                    this.info = i18n.t('doorCheck.docsclerk-putin')
                    this.amount = this.doorInfo['amount']
                }
                else {//TRANS_TYPE.STOREHOST
                    this.info = this.doorInfo['product_name'] + i18n.t('doorCheck.store-goods')
                    this.amount = this.doorInfo['fee']
                }
            } else if (this.doorInfo['is_available'] === false) {
                if ((this.doorInfo['status'] & MQTT_ACTION.CONTROL_ERROR) == MQTT_ACTION.CONTROL_ERROR) {
                    this.info = i18n.t('doorCheck.putin-door-error')
                    this.canLock = false
                } else if (((this.doorInfo['status'] & MQTT_ACTION.TAKEOUT_ACTION) != MQTT_ACTION.TAKEOUT_ACTION) && ((this.doorInfo['status'] & MQTT_ACTION.PUTIN_FINISH) == MQTT_ACTION.PUTIN_FINISH)){
                    this.info = i18n.t('doorCheck.takeout-door-error')
                    this.canLock = false
                } else {
                    this.info = i18n.t('doorCheck.other-door-error')
                    this.canLock = false
                }
            } else {
                this.info = i18n.t('doorCheck.door-can-use')
            }
            this.isAvailable = this.doorInfo['is_available']
            this.track_no = this.doorInfo['track_no']
        },
        checkDoor(box_id, check_mode) {
            this.check_mode = check_mode
            this.isDisabled = true
            var topic = `box/${box_id}/doors/check`
            publish(topic, {
                user_id: store.getters.user.id,
                client_id: store.getters.clientId,
                door_ids: [this.doorId],
                door_close: check_mode
            }, 1)

            this.timeout = setTimeout(this.stopCheckDoor, CHECK_DOOR_TIMEOUT)
        },
        stopCheckDoor() {
            this.check_mode = -1
            this.isDisabled = false
            clearTimeout(this.timeout)
            store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + this.doorId + i18n.t('doorCheck.fail-check-cell-timeout'), color: 'error' })
        },
        handleMQTTMessage(message) {
            if (message === null) console.log('[LockDoor] MQTT message is Null')
            else if (message.topic === 'user/' + store.getters.user.id + '/lock/door' && message.payload.box_id === this.boxId) this.handleLockDoorReport(message)
        },
        handleLockDoorReport(message) {
            this.closeDialog()
            let controlOK = message.payload.lock[this.doorId]
            clearTimeout(this.timeout)
            if (controlOK === -1) {
                this.canLock = false
                this.showMsgDialog(i18n.t(`lockDoor.cell`)+ ' '+ this.doorId+ ' ' + i18n.t('lockDoor.success-lock-cell'), i18n.t('lockDoor.finish'))
                this.closeMsgDialogFunc = () => {}
            } else {
                this.canLock = true
                this.showMsgDialog(i18n.t(`lockDoor.cell`)+ ' '+ this.doorId+ ' ' + i18n.t('lockDoor.fail-lock-cell'), i18n.t('lockDoor.finish'))
                this.closeMsgDialogFunc = () => {}
            }
        },
        stopLockDoor() {
            this.closeDialog()
            this.canLock = true
            clearTimeout(this.timeout)
            store.commit('setSnackBar', { message: i18n.t('doorSelector.cell') + this.doorId + i18n.t('lockDoor.fail-lock-cell-timeout'), color: 'error' })
        },
        checkMsg(check_mode) {
            if (this.isDisabled == true) {
                if (check_mode === 0)
                    return i18n.t('doorCheck.wait-check-open-door')
                else
                    return i18n.t('doorCheck.wait-check-close-door')
            } else {
                if (check_mode === 0)
                    return i18n.t('doorCheck.check-open-door')
                else
                    return i18n.t('doorCheck.check-close-door')
            }
        },
        conti(){
            this.doorId = 0
            this.doorInfo = null
            this.canLock = false
            clearTimeout(this.timeout)
            this.check_mode = -1
            this.setStepNo(2)
        },
        leave() {
            this.init()
            store.commit('setFromViews', '')
            this.$router.push(`/${i18n.locale}/logistics/menu`)
        },
        lockUrl() {
            if (this.canLock === true) {
                let payload = {
                    client_id: store.getters.clientId,
                    door_id: this.doorId,
                    track_no: this.track_no
                }
                this.showDialog(i18n.t(`lockDoor.cell`)+ ' '+ this.doorId+ ' ' +i18n.t(`lockDoor.lock`), i18n.t(`lockDoor.fail-lock-cell`), RESET_DOOR_TIMEOUT)
                let url = HostUrl + `/api/staff/` + this.boxId + `/door/lock`
                let self = this
                axios.post(url, payload, getAxiosConfig()).then(function (response) {
                    if (response.data.code === 0) {
                        self.timeout = setTimeout(self.stopLockDoor, CHECK_DOOR_TIMEOUT)
                    }
                    else {
                        self.closeDialog()
                        store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                    }
                }).catch(function () {
                    self.closeDialog()
                    store.commit('setSnackBar', { message: i18n.t('lockDoor.transaction-fail'), color: 'error' })
                })
            }
            else {
                this.showMsgDialog(i18n.t('lockDoor.done-lock-cell'), i18n.t('lockDoor.finish'))
                this.closeMsgDialogFunc = () => {}
            }
        }
    },
    activated(){
        store.commit('setFromViews', ASSIGN_VIEW.LOCK)
    },
    deactivated() {
        this.doorId = 0
        this.canLock = true
        store.commit('setFromViews', '')
        clearTimeout(this.timeout)
        this.check_mode = -1
    }
};
</script>

<style scoped>
</style>
